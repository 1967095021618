import {useEffect, useRef} from 'react';
import useWindowSize from '../hooks/useWindowSize';
import * as PIXI from "pixi.js"
import {Application, AnimatedSprite, Spritesheet} from 'pixi.js'
import song from '../assests/daddysong.mp3'

const loader = PIXI.Loader.shared;
let sprites = {}
let spriteOrder = []

const PixiContainer = () => {
  const [width, height] = useWindowSize();

  const appRef = useRef();
  const divRef = useRef();
  const audioRef = useRef();

  const makeSprite = (name, app) => {
    return new Promise(res => {
      import('../assests/' + name + '.png').then(img => {
        const imgPath = img.default;
        const sprite = PIXI.Sprite.from(imgPath)
        // app.stage.addChild(sprite)
        sprites = {
          ...sprites,
          [name]: sprite
        }
        res("sprite " + name + " loaded")           
      })
    })
  }

  const makeAnimatedSpriteSheet = (name, app) => {
    return new Promise(res => {
      import('../assests/' + name + '.png').then(img => {
        const imgPath = img.default;
        import('../assests/' + name + '.json').then(json => {
          const data = json.default;
          const frameCount = Object.keys(data.frames).length
          const sheet = new Spritesheet(
            new PIXI.Texture.from(imgPath),
            data
          );
          sheet.parse((...args) => {
            const frames = args[0];
            console.log(frames)
            const textures = [];
            for (let i = 0; i < frameCount; i++) {
              let frame =  "000" + i;
              if (i < 10) {
                frame = "0" + frame
              }
              textures.push(frames[name + "_" + frame + ".png"])
            }
            const sprite = new AnimatedSprite(textures)
            // app.stage.addChild(sprite)
            sprites = {
              ...sprites,
              [name]: sprite
            }
            res("sprite " + name + " loaded")           
          });
        })
      })
    })
  }

  const updateAnimatedSprite = (params) => {
    const {name, x, y, scale, animationSpeed} = params
    sprites[name].x = x
    sprites[name].y = y
    sprites[name].anchor.set(0.5)
    sprites[name].scale.set(scale)
    sprites[name].animationSpeed = animationSpeed
    sprites[name].play()
  }

  const updateSprite = (params) => {
    const {name, x, y, scale} = params
    sprites[name].x = x
    sprites[name].y = y
    sprites[name].anchor.set(0.5)
    sprites[name].scale.set(scale)
  }

  const gameLoop = (delta) => {

  }
  useEffect(() => {

    function generateSpriteSheets(app) {
      return new Promise(res => {
        let spriteList = ["star", "planet3", "planet1", "planet2", "planet4", "grid", "struct4", "struct1", "struct2"]
        let animatedSpriteList = ['snake', 'fish', 'bench', 'elephant', 'crossbow', 'princess', 'earth', 'human', 'mantis']
        spriteOrder = [...spriteList, ...animatedSpriteList]
        animatedSpriteList = animatedSpriteList.map(sprite => makeAnimatedSpriteSheet(sprite, app))
        spriteList = spriteList.map(sprite => makeSprite(sprite, app))
        Promise.all([...spriteList, ...animatedSpriteList]).then(() => {
          res("all sprites rendered")
        })
      })
    }

    function generateWorld (app) {
      console.log(sprites)
      updateAnimatedSprite({
        name: "snake",
        x: 500,
        y: 500,
        scale: 0.1,
        zIndex: 11,
        animationSpeed: 0.15
      })
      updateAnimatedSprite({
        name: "fish",
        x: 500,
        y: 400,
        scale: 0.3,
        zIndex: 12,
        animationSpeed: 0.25
      })
      updateAnimatedSprite({
        name: "bench",
        x: 1400,
        y: 500,
        scale: 0.3,
        animationSpeed: 0.25
      })
      updateAnimatedSprite({
        name: "crossbow",
        x: 700,
        y: 550,
        scale: 0.7,
        animationSpeed: 0.25
      })
      updateAnimatedSprite({
        name: "earth",
        x: 300,
        y: 540,
        scale: 0.67,
        animationSpeed: 0.25
      })
      updateAnimatedSprite({
        name: "elephant",
        x: 800,
        y: 400,
        scale: 0.3,
        animationSpeed: 0.25
      })
      updateAnimatedSprite({
        name: "human",
        x: 1200,
        y: 400,
        scale: 0.15,
        animationSpeed: 0.25
      })
      updateAnimatedSprite({
        name: "mantis",
        x: 100,
        y: 7000,
        scale: 0.9,
        animationSpeed: 0.25
      })
      updateAnimatedSprite({
        name: "princess",
        x: 400,
        y: 450,
        scale: 0.3,
        animationSpeed: 0.25
      })
      updateSprite({
        name: "grid",
        x: 500,
        y: 550,
        scale: 1.0,
        zIndex: 10
      })
      updateSprite({
        name: "struct1",
        x: 200,
        y: 370,
        scale: 0.6,
        zIndex: 11
      })
      updateSprite({
        name: "struct2",
        x: 1000,
        y: 450,
        scale: 1.3,
        zIndex: 12
      })
      updateSprite({
        name: "struct4",
        x: 1100,
        y: 450,
        scale: 0.3,
        zIndex: 14
      })
      updateSprite({
        name: "star",
        x: 950,
        y: 127.3,
        scale: 0.1,
        zIndex: 11
      })
      updateSprite({
        name: "planet1",
        x: 100,
        y: 100,
        scale: 0.45,
        zIndex: 3
      })
      updateSprite({
        name: "planet2",
        x: 700,
        y: 239,
        scale: 0.4,
        zIndex: 11
      })
      updateSprite({
        name: "planet3",
        x: 500,
        y: 300,
        scale: 0.8,
        zIndex: 0
      })
      updateSprite({
        name: "planet4",
        x: 1200,
        y: 341,
        scale: 0.3,
        zIndex: 1
      })
      var text = new PIXI.Text("Happy Bday dad", {font:"50px Arial", fill:"white"});
      app.stage.addChild(text);
      text.x = 220
      text.y = 50
      app.ticker.add((delta) => gameLoop(delta))
    }
  

    appRef.current = new Application({
      width: window.innerWidth,
      height: window.innerHeight,
      antialias: true
    });
    const app = appRef.current;
    generateSpriteSheets(app).then(() => {
      generateWorld(app)
      spriteOrder.forEach(sprite => {
        app.stage.addChild(sprites[sprite])
      })
    })
    const event = () => {
      audioRef.current.play()
      window.removeEventListener('click', event) 
    }

    window.addEventListener('click', event)
      divRef.current.append(app.view)
    // })

      return () => {
        loader.reset()
        window.removeEventListener('click', event) 
      }
    }, [])

    // loader.add("portal", portalData);



    //   console.log(textures)
    //   const portal = new AnimatedSprite(textures);
    //   portal.position(800, 300);
    //   app.stage.addChild(portal)
    // }

    // loader.load(setup)

  useEffect(() => {
    const app = appRef.current;
    app.renderer.resize(width, height)
  }, [width, height])

  return (
    <>    
      <audio ref={audioRef} autoPlay loop={true} src={song} ></audio>
      <div ref={divRef}>
      </div>
    </>
  );
};

export default PixiContainer;